import React from "react";
import { Box } from "@material-ui/core";
import QuickBooksLive from "../components/QuickBooksLive/QuickBooksLive";
const QuickBooks = () => {

    return (
        <Box>
            {/* <Navbar /> */}
            <QuickBooksLive />
            {/* <div className="flex flex-row w-full bg-white h-full">
                
            </div> */}
        </Box>
    );
};

export default QuickBooks;
